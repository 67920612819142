import { useCallback, useEffect, useMemo, useState } from 'react';
import BottomSheet from '../../lib/components/BottomSheet';
import ProductCard from '../../lib/components/ProductCard';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router';
import { useAppStore } from '../../lib/store/appMain';
import { getProductById, IProductInfo } from '../../lib/utils/apis';
import { useSearchParams } from 'react-router-dom';

function Welcome() {
	const { APP, set } = useAppStore(state => state);
	const [searchParams] = useSearchParams();
	const productId = searchParams.get('product');
	const [showModal, setShowModal] = useState(false);
	const [productInfo, setProductInfo] = useState<IProductInfo>();
	const navigate = useNavigate();

	function handleSubmit() {
		setShowModal(true);
	}

	const handleSubmitPostList = useCallback(
		function handleSubmitPostList() {
			navigate('/post-list');
		},
		[navigate],
	);

	function handleSubmitModal() {
		const params = new URLSearchParams();
		params.append('productid', productInfo?._id || '');
		navigate(`/post-review?${params.toString()}`);
	}

	async function fetchProductDetails(id: string) {
		const productInfoResponse = await getProductById(id);

		setProductInfo(productInfoResponse);
	}

	const renderProductCardOrPostListCta = useMemo(
		function renderProductCardOrPostListCta() {
			if (productId) {
				return (
					<>
						<div>
							<ProductCard
								name={productInfo?.name}
								image={productInfo?.image}
								rewards={productInfo?.rewards}
							/>
						</div>
						<div className="d-flex justify-content-center w-100 mt-3">
							<button
								onClick={handleSubmit}
								type="submit"
								className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
							>
								<p className="m-0">Continue</p>
							</button>
						</div>
					</>
				);
			}

			return (
				<div className="d-flex justify-content-center w-100 mt-3">
					<button
						onClick={handleSubmitPostList}
						type="submit"
						className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
					>
						<p className="m-0">Go To Submissions</p>
					</button>
				</div>
			);
		},
		[productId, productInfo, handleSubmitPostList],
	);

	useEffect(() => {
		if (!productId) return;
		fetchProductDetails(productId);
		set({
			ProductId: undefined,
		});
	}, [productId, set]);

	return (
		<>
			<div className="d-flex flex-column gap-3">
				<div className="d-flex flex-column align-items-center gap-3">
					<div
						className={`${styles.logoImage} align-self-center rounded-pill`}
						style={{
							backgroundImage: `url(${APP.User?.Image})`,
						}}
					></div>
					<p className="fs-5 fw-bold">Hi {APP.User?.Name}.</p>
					<p className="fs-5 text-center">
						Share your experience with our product on Instagram and
						get amazing rewards.
					</p>
				</div>
				<div>
					<ul>
						<li>
							Show others the genuine value of the brand's
							offerings and help them appreciate it fully.
						</li>
						<li>
							Post your review and earn crazy brand discount
							coupons for your next purchase.
						</li>
						<li>
							Get your collaboration approved and expand your
							profile’s reach to thousands of brand followers.
						</li>
					</ul>
				</div>
				{renderProductCardOrPostListCta}
			</div>
			{showModal && (
				<BottomSheet handleClose={() => setShowModal(false)}>
					<>
						<i className="bi bi-info-circle text-white text-center fs-1 my-3"></i>
						<p className="fw-bold fs-5 text-center">
							Here are the key points for creating content for the
							brands.
						</p>
						<ul>
							<li>
								Ensure the product you're reviewing is visible
								in the review video.
							</li>
							<li>
								Posting of the content will be subjected to
								brand approval.
							</li>
							<li>
								Coupon will be credited once the brand approves
								the content.
							</li>
							<li>
								Collaboration on Instagram will need additional
								approval from the brand.
							</li>
						</ul>
						<div className="d-flex justify-content-center w-100 mt-3 mb-5">
							<button
								onClick={handleSubmitModal}
								type="submit"
								className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
							>
								<p className="m-0">Create a review</p>
							</button>
						</div>
					</>
				</BottomSheet>
			)}
		</>
	);
}

export default Welcome;
