import {
	PropsWithChildren,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useAppStore } from '../../../store/appMain';
import styles from './styles.module.scss';
import { Outlet } from 'react-router';
import BottomSheet from '../../BottomSheet';
import { deleteUserData } from '../../../utils/apis';
import Notices from '../../Notices';
import { useNoticeStore } from '../../../store/notice';

interface IBottomSheetContentRefData {
	type: 'logout' | 'delete';
	title: string;
	subTitle: string;
}

function Main(props: PropsWithChildren) {
	const { children } = props;
	const { APP, set } = useAppStore(state => state);
	const { notices } = useNoticeStore(state => state);
	const [showMenu, setShowMenu] = useState(false);
	const [showBottomSheet, setShowBottomSheet] = useState(false);
	const bottomSheetContentRef = useRef<IBottomSheetContentRefData | null>();

	function handleHamburgerClick() {
		setShowMenu(prev => !prev);
	}

	const handleLogoutClick = useCallback(function handleLogoutClick() {
		setShowBottomSheet(true);
		bottomSheetContentRef.current = {
			type: 'logout',
			title: 'Are you sure?',
			subTitle:
				'This action will logout and you will need to login again.',
		};
	}, []);

	const handleDeleteMyDataClick = useCallback(
		function handleDeleteMyDataClick() {
			setShowBottomSheet(true);
			bottomSheetContentRef.current = {
				type: 'delete',
				title: 'Are you sure?',
				subTitle:
					'This action will delete your data from our database immediately.',
			};
		},
		[],
	);

	const handleSubmitFromBottomSheet = useCallback(async () => {
		if (!bottomSheetContentRef.current) return;

		switch (bottomSheetContentRef.current.type) {
			case 'logout':
				break;

			case 'delete': {
				await deleteUserData();
				break;
			}
		}
		set({
			User: undefined,
			Auth: undefined,
			ProductId: undefined,
			TermsAccepted: false,
		});
		window.location.href = '/';
	}, [set]);

	const renderHamBurger = useMemo(
		function renderHamBurger() {
			if (APP.Auth?.AccessToken) {
				return (
					<div className="position-relative d-flex justify-content-end">
						<button className="btn">
							<i
								className="bi bi-list text-white fs-3"
								onClick={handleHamburgerClick}
							></i>
						</button>
						{showMenu && (
							<div
								className={`position-absolute p-2 rounded top-100 text d-flex flex-column ${styles.hamburgerBg}`}
							>
								<button
									className="btn text-start"
									onClick={handleLogoutClick}
								>
									<i className="bi bi-box-arrow-right me-2" />
									Logout
								</button>
								<button
									className="btn text-start"
									onClick={handleDeleteMyDataClick}
								>
									<i className="bi bi-trash3 me-2" />
									Delete my data
								</button>
							</div>
						)}
					</div>
				);
			}
			return null;
		},
		[APP.Auth, showMenu, handleLogoutClick, handleDeleteMyDataClick],
	);

	const handleCloseBottomSheet = useCallback(
		function handleCloseBottomSheet() {
			setShowBottomSheet(false);
		},
		[],
	);

	const renderBotttomSheet = useMemo(
		function renderBotttomSheet() {
			if (showMenu && showBottomSheet) {
				return (
					<BottomSheet handleClose={handleCloseBottomSheet}>
						<i className="bi bi-exclamation-circle-fill text-white text-center fs-1 my-3"></i>
						<p className="fw-bold fs-5 text-center">
							{bottomSheetContentRef.current?.title}
						</p>
						<p className="text-center">
							{bottomSheetContentRef.current?.subTitle}
						</p>
						<div className="d-flex justify-content-center w-100 mt-3 mb-5">
							<button
								onClick={handleSubmitFromBottomSheet}
								type="submit"
								className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
							>
								<p className="m-0">I Understand</p>
							</button>
						</div>
					</BottomSheet>
				);
			}

			return null;
		},
		[
			handleCloseBottomSheet,
			showMenu,
			showBottomSheet,
			handleSubmitFromBottomSheet,
		],
	);

	return (
		<>
			<Notices notices={notices} />
			{renderHamBurger}
			{children}
			<Outlet />
			{renderBotttomSheet}
		</>
	);
}

export default Main;
