import { Link } from 'react-router-dom';

function Success() {
	return (
		<div className="d-flex align-items-center justify-content-center h-100 flex-column">
			<p className="text-center mb-4">
				Submitted. <br />
				Your content is awaiting the approval.
			</p>
			<Link
				to="/post-list"
				type="submit"
				className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
			>
				<p className="m-0">Go To Submissions</p>
			</Link>
		</div>
	);
}

export default Success;
