import {
	ChangeEvent,
	FormEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router';
import { useAppStore } from '../../lib/store/appMain';
import {
	checkUploadStatus,
	getProductById,
	IAppUploadProgressInfo,
	IProductInfo,
	postReview,
	uploadInit,
} from '../../lib/utils/apis';
import { useSearchParams } from 'react-router-dom';
import BottomSheet from '../../lib/components/BottomSheet';
import HashtagsSection from './components/HashtagsSection';
import Required from '../../lib/components/Required';

function PostReview() {
	const { APP } = useAppStore(state => state);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const uploadVideoRef = useRef<HTMLVideoElement>(null);
	const intervalRef = useRef<ReturnType<typeof setInterval>>();
	const [hastagInputText, setHashtagInputText] = useState('');
	const [uploadVideoInfo, setUploadVideoInfo] =
		useState<IAppUploadProgressInfo>();
	const [videoUploadProgress, setVideoUploadProgress] = useState(-1);
	const [caption, setCaption] = useState('');
	const [hashtags, setHashtags] = useState(
		APP.Agency?.Hashtags.map(item => {
			const obj = {
				id: Math.round(Math.random() * 100000000) + '',
				label: item,
				dismissable: false,
			};
			return obj;
		}) || [],
	);
	const [productInfo, setProductInfo] = useState<IProductInfo>();
	const [postSubmitLoading, setPostSubmitLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const productID = searchParams.get('productid');

	function handleCaptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
		setCaption(event?.target.value);
	}

	const handleHashtagClose = useCallback(
		function handleHashtagClose(id: string) {
			const filterredHashtags = hashtags.filter(
				hashtag => hashtag.id !== id,
			);
			setHashtags(filterredHashtags);
		},
		[hashtags],
	);

	function handleSubmit() {
		setShowModal(true);
	}

	async function handleSubmitModal() {
		if (!productInfo || !uploadVideoInfo)
			throw new Error('Poduct ID and video ID should not be empty.');
		const body = `${caption} ${hashtags.map(hashtag => `#${hashtag.label.replaceAll(' ', '')}`).join(' ')}`;
		const params = {
			productId: productInfo._id,
			uploadId: uploadVideoInfo.id,
			caption: body,
		};
		try {
			setPostSubmitLoading(true);
			await postReview(params);
			navigate('/wip/success');
		} finally {
			setPostSubmitLoading(false);
		}
	}

	async function handleChangeVideoInput(
		event: ChangeEvent<HTMLInputElement>,
	) {
		setVideoUploadProgress(-1);

		if (!event.target.files) return;
		const file = event.target.files[0];

		if (!uploadVideoRef.current) return;
		// on change video
		uploadVideoRef.current.src = '';
		setVideoUploadProgress(0);

		const formdata = new FormData();
		formdata.append('file', file);

		const uploadInfo = await uploadInit(formdata);
		const { id } = uploadInfo || {};
		if (!id) {
			throw Error('Server Error ❗️');
		}

		setUploadVideoInfo(uploadInfo);
		intervalRef.current = setInterval(async () => {
			const { progress = 0, url } = (await checkUploadStatus(id)) || {};
			setUploadVideoInfo(uploadInfo);
			setVideoUploadProgress(progress);

			if (progress === 100 && url) {
				if (uploadVideoRef.current) {
					uploadVideoRef.current.src = url;
					uploadVideoRef.current.controls = true;
				}
				clearInterval(intervalRef.current);
			}
		}, 500);
	}

	const handleAddCustomHashtag = useCallback(
		function handleAddCustomHashtag(event: FormEvent) {
			event.preventDefault();
			const newHashtagObj = {
				id: Math.round(Math.random() * 100000000) + '',
				label: hastagInputText,
				dismissable: true,
			};
			setHashtags(prev => [...prev, newHashtagObj]);
			setHashtagInputText('');
		},
		[hastagInputText, setHashtags, setHashtagInputText],
	);

	const fetchProductDetails = useCallback(
		async function fetchProductDetails() {
			if (!productID) throw new Error('Poduct ID should not be empty.');
			const productInfoResponse = await getProductById(productID);

			if (!productInfoResponse)
				throw new Error('Invalid Product Response');

			setProductInfo(productInfoResponse);
			const hashtags = productInfoResponse?.hashtags.map(
				(item, index) => {
					return { id: index + '', label: item, dismissable: false };
				},
			);
			setHashtags(prev => [...prev, ...hashtags]);
			setCaption(productInfoResponse.caption || '');
		},
		[productID],
	);

	function handleOnChangeHashtagText(text: string) {
		setHashtagInputText(text);
	}

	const disabled = useMemo(
		function checkDisabled() {
			if (caption && uploadVideoInfo?.id && videoUploadProgress === 100) {
				return false;
			}
			return true;
		},
		[caption, uploadVideoInfo, videoUploadProgress],
	);

	useEffect(
		function useEffect() {
			fetchProductDetails();
		},
		[fetchProductDetails],
	);

	if (postSubmitLoading) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100">
				<p>Submitting...</p>
			</div>
		);
	}

	return (
		<div className="d-flex flex-column gap-5">
			<div className="d-flex gap-3">
				<div
					className={`${styles.logoImage} align-self-center rounded-pill`}
					style={{
						backgroundImage: `url(${productInfo?.image})`,
					}}
				></div>
				<div className="d-flex flex-column justify-content-center">
					<p className="fw-bold fs-5">{productInfo?.name}</p>
					<p className="">{APP.Agency?.Name}</p>
				</div>
			</div>
			<div className="d-flex flex-column gap-4">
				<div>
					<li className="fw-bold">
						Upload Video <Required />
					</li>
					<div
						className={`${styles.uploadWrapper} mt-2 rounded p-2 w-100 d-flex justify-content-center position-relative`}
					>
						<div
							className={`d-flex align-items-center ${styles.videoWrapper}`}
						>
							{videoUploadProgress >= 0 &&
								videoUploadProgress < 100 && (
									<div className="text-center">
										<p>
											{Math.floor(videoUploadProgress)}%
										</p>
										<div
											className="spinner-border text-white mt-2"
											role="status"
										></div>
									</div>
								)}
							<video
								ref={uploadVideoRef}
								className={`h-100 ${videoUploadProgress === 100 ? '' : 'd-none'}`}
							/>
						</div>
						<div className="position-absolute end-0 px-3">
							<div>
								<i
									onClick={() =>
										fileInputRef.current?.click()
									}
									className="bi bi-upload text-primary"
								></i>
							</div>
							<input
								className="d-none"
								onChange={handleChangeVideoInput}
								ref={fileInputRef}
								type="file"
								accept="video/*, image/png, image/jpeg"
							/>
						</div>
					</div>
				</div>
				<div>
					<li className="fw-bold">
						Caption <Required />
					</li>
					<div className="mt-2">
						<textarea
							className={`w-100 p-2 outline-0 rounded border-0 ${styles.captionTextArea}`}
							value={caption}
							onChange={handleCaptionChange}
							rows={6}
						></textarea>
					</div>
				</div>
				<HashtagsSection
					hashtags={hashtags}
					handleOnChangeHashtagText={handleOnChangeHashtagText}
					handleHashtagClose={handleHashtagClose}
					handleAddCustomHashtag={handleAddCustomHashtag}
					hastagInputText={hastagInputText}
				/>
				<div className="d-flex justify-content-center w-100 mt-3 mb-4">
					<button
						disabled={disabled}
						onClick={handleSubmit}
						type="submit"
						className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
					>
						<p className="m-0">Continue</p>
					</button>
				</div>
			</div>
			{showModal && (
				<BottomSheet handleClose={() => setShowModal(false)}>
					<>
						<i className="bi bi-info-circle text-white text-center fs-1 my-3"></i>
						<p className="fw-bold fs-5 text-center">
							Share content
						</p>
						<p className="mt-3">
							This content will be shared on your Instagram
							account upon brand approval.
						</p>
						<div className="d-flex justify-content-center w-100 my-5">
							<button
								onClick={handleSubmitModal}
								type="submit"
								className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded w-75"
							>
								<p className="m-0">Submit</p>
							</button>
						</div>
					</>
				</BottomSheet>
			)}
		</div>
	);
}

export default PostReview;
