import { useEffect, useState } from 'react';
import { IPostListInfo, postList } from '../../lib/utils/apis';
import ProductCard from '../../lib/components/ProductCard';

function PostList() {
	const [productsList, setProductsList] = useState<IPostListInfo>();
	const [loading, setLoading] = useState(true);

	async function fetchPostsListDetails() {
		const postListDetails = await postList();
		setProductsList(postListDetails);
		setLoading(false);
	}

	useEffect(() => {
		fetchPostsListDetails();
	}, []);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100">
				<p>Loading...</p>
			</div>
		);
	}

	if (!productsList || !productsList.posts || !productsList.posts.length) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100">
				<p>No submissions available ⚠️.</p>
			</div>
		);
	}

	return (
		<div>
			<p className="fw-bold fs-2">Your Submissions.</p>
			<div className="d-flex flex-column gap-3 mt-4">
				{productsList.posts.map((product, index) => {
					return (
						<ProductCard
							showRewards={false}
							key={index}
							status={product.status}
							name={product?.productName}
							image={product?.productImage}
							rewards={product?.rewards}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default PostList;
